import React from 'react';
import classNames from 'classnames';

const style = require('./ButtonGroup.module.scss');

type ButtonGroupProps = {
  children: React.ReactNode;
  className?: string;
  wrapping?: 'reverse' | 'direct';
  [otherProps: string]: any;
};

const ButtonGroup = (props: ButtonGroupProps) => {
  const { children, className, wrapping, ...rest } = props;

  return (
    <div
      {...rest}
      className={classNames(
        style.buttonGroup,
        {
          [style.directWrapping]: wrapping === 'direct',
          [style.reverseWrapping]: wrapping === 'reverse',
        },
        className
      )}
    >
      {children}
    </div>
  );
};

ButtonGroup.defaultProps = {
  className: '',
  wrapping: 'reverse',
};

export default ButtonGroup;
