// extracted by mini-css-extract-plugin
export var services = "Services-module--services--cSWpo";
export var comparisonLink = "Services-module--comparisonLink--1PuKx";
export var rightBlock = "Services-module--rightBlock--amfsi";
export var subtitleComparison = "Services-module--subtitleComparison--1FVFV";
export var comparison = "Services-module--comparison--3ikwF";
export var img = "Services-module--img--3pK7m";
export var solarLink = "Services-module--solarLink--fvOAu";
export var solar = "Services-module--solar--1_MT1";
export var subtitleSolar = "Services-module--subtitleSolar--2gaLV";
export var linksContainer = "Services-module--linksContainer--i4cWf";
export var title = "Services-module--title--2aESl";
export var bottomLinks = "Services-module--bottomLinks--1bpST";
export var topLinks = "Services-module--topLinks--2_eyX";