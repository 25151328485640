import React from 'react';
// @ts-ignore
import { ReactComponent as Arrow } from '../../../assets/vectors/arrow.svg';

const style = require('./MenuItem.module.scss');

interface MenuItemProps {
  children: React.ReactNode;
  type: 'item' | 'helpCenter' | 'company' | 'services';
  [otherProps: string]: any;
}

export const MenuItem = (props: MenuItemProps): JSX.Element => {
  const {
    children,
    type,
    onMouseEnterHelpCenter,
    onMouseEnterCompany,
    onMouseEnterServices,
    onMouseLeave,
  } = props;

  switch (type) {
    case 'helpCenter':
      return (
        <div className={style.helpCenter} onMouseEnter={onMouseEnterHelpCenter}>
          <div className={style.box}>
            <span>{children}</span>
            <Arrow />
          </div>
        </div>
      );
    case 'company':
      return (
        <div className={style.company} onMouseEnter={onMouseEnterCompany}>
          <div className={style.box}>
            <span>{children}</span>
            <Arrow />
          </div>
        </div>
      );
    case 'services':
      return (
        <div className={style.services} onMouseEnter={onMouseEnterServices}>
          <div className={style.box}>
            <span>{children}</span>
            <Arrow />
          </div>
        </div>
      );
    case 'item':
      return (
        <div className={style.item} onMouseLeave={onMouseLeave}>
          <span>{children}</span>
        </div>
      );
    default:
      return <></>;
  }
};
