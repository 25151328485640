import React from 'react';

// @ts-ignore
import { ReactComponent as Comparison } from '../../../assets/vectors/Comparison.svg';
// @ts-ignore
import { ReactComponent as Solar } from '../../../assets/vectors/solarStorage.svg';

const style = require('./Services.module.scss');

export const Services = () => {
  return (
    <ul className={style.services}>
      <li>
        <a
          href="https://www.huglo.com.au/comparison"
          target="_blank"
          rel="noreferrer nofollower"
          className={style.comparisonLink}
        >
          <div className={style.comparison}>
            <div className={style.img}>
              <Comparison />
            </div>
            <div className={style.rightBlock}>
              <h4>Electricity Comparison</h4>
              <p className={style.subtitleComparison}>
                Compare prices from 21,000+ plans. 100% of the market, 100%
                free.
              </p>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a
          href="https://www.huglo.com.au/solar"
          target="_blank"
          rel="noreferrer nofollower"
          className={style.solarLink}
        >
          <div className={style.solar}>
            <div className={style.img}>
              <Solar />
            </div>
            <div className={style.rightBlock}>
              <h4>Buy Solar + Storage</h4>
              <p className={style.subtitleSolar}>
                Start saving 58%+ on your electricity bill from the day of your
                install.
              </p>
            </div>
          </div>
        </a>
      </li>
    </ul>
  );
};
