import React from 'react';

const style = require('./Button.module.scss');

type ButtonProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'link';
  type: 'button' | 'reset' | 'submit';
  // eslint-disable-next-line react/boolean-prop-naming
  disabled?: boolean;
  className?: string;
  [otherProps: string]: any;
};

const Button = (props: ButtonProps) => {
  const { children, variant, disabled, className, type, ...rest } = props;

  const getClassNameFromVariant = (variantString?: string) => {
    switch (variantString) {
      case 'secondary':
        return style.buttonSecondary;
      case 'tertiary':
        return style.buttonTertiary;
      case 'link':
        return style.buttonLink;
      case 'primary':
      default:
        return style.buttonPrimary;
    }
  };

  return (
    <button
      {...rest}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={`
      ${style.button} 
      ${getClassNameFromVariant(variant)} 
      ${disabled ? style.buttonDisabled : ''} 
      ${className} `}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  disabled: false,
  variant: 'primary',
};

export default Button;
