import React, { useState } from 'react';
import { navigate } from 'gatsby';
// @ts-ignore
import { ReactComponent as LogoWTagline } from '../../../assets/vectors/LogoWTagline.svg';
import { MenuItem } from '../MenuItem';
import { DropDownMenuItemContainer } from '../../general/DropDownMenuItemContainer';
import { Company } from '../../general/Company';
import { Services } from '../../general/Services';

const style = require('./Header.module.scss');

type HeaderProps = {
  hasNav?: boolean;
  [otherProps: string]: any;
}

const Header = ({ hasNav, ...restProps }: HeaderProps) => {

  const [company, setCompany] = useState(false);
  const [services, setServices] = useState(false);

  const onMouseEnterCompany = () => {
    setCompany(true);
    setServices(false);
  };

  const onMouseEnterServices = () => {
    setServices(true);
    setCompany(false);
  };

  const onMouseLeave = () => {
    setServices(false);
    setCompany(false);
  };

  const navigateToHome = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <header {...restProps} className={style.header}>
      <a
        href="/"
        onClick={navigateToHome}
      >
        <LogoWTagline width={250} height={41} />
      </a>
      {hasNav && (
        <div className={style.navContainer}>
          <nav className={style.navBar} onMouseLeave={onMouseLeave}>
            <MenuItem
              type="services"
              onMouseEnterServices={onMouseEnterServices}
            >
              Our Services
            </MenuItem>
            <MenuItem type="company" onMouseEnterCompany={onMouseEnterCompany}>
              Company
            </MenuItem>
            {company && (
              <DropDownMenuItemContainer onMouseLeave={onMouseLeave}>
                <Company />
              </DropDownMenuItemContainer>
            )}
            {services && (
              <DropDownMenuItemContainer onMouseLeave={onMouseLeave}>
                <Services />
              </DropDownMenuItemContainer>
            )}
          </nav>
          <a
            href="https://www.huglo.com.au/contact"
            target="_blank"
            rel="noreferrer nofollower"
            style={{ textDecoration: 'none' }}
          >
            <MenuItem type="item" onMouseLeave={onMouseLeave}>
              Contact
            </MenuItem>
          </a>
        </div>
      )}
    </header>
  );
};

Header.defaultProps = {
  hasNav: false,
}

export default Header;
