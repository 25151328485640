import React from 'react';

const style = require('./Company.module.scss');

export const Company = () => {
  return (
    <ul className={style.company}>
      <li>
        <a
          href="https://www.huglo.com.au/about-us"
          target="_blank"
          rel="noreferrer nofollower"
        >
          About Us
        </a>
      </li>
      <li>
        <a
          href="https://www.huglo.com.au/blog"
          target="_blank"
          rel="noreferrer nofollower"
        >
          Blog
        </a>
      </li>
      <li>
        <a
          href="https://www.huglo.com.au/retailers"
          target="_blank"
          rel="noreferrer nofollower"
        >
          Retailers
        </a>
      </li>
      <li>
        <a
          href="https://www.huglo.com.au/solar"
          target="_blank"
          rel="noreferrer nofollower"
        >
          Products
        </a>
      </li>
    </ul>
  );
};
