import React from 'react';

const style = require('./DropDownMenuItemContainer.module.scss');

interface DropDownMenuItemContainerProps {
  children: React.ReactNode;
  [otherProps: string]: any;
}

export const DropDownMenuItemContainer = (
  props: DropDownMenuItemContainerProps
) => {
  const { children, onMouseLeave } = props;

  return (
    <div className={style.container} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
};
